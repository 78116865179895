import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { setMintPriceForDataId } from '../../../../helpers/web3'

function SetMintPriceForDataId(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [dataId, setDataId] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const updateMintPrice = async () => {
    setLoading(true)

    try {
        await setMintPriceForDataId(dataId, price, config.address, config.chainId, config.web3, contractDetails.address, contractDetails.variant)
        toast.success('Mint price updated!')
        setLoading(false)
    }
    catch(err) {
        console.log(err)
        setLoading(false)
        toast.error('Error updating mint price.')
    }
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 fw-bolder mb-6'>
                Set Mint Price for Data ID
            </p>

            <div className='mb-5 w-75'>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder='Price'
                className='form-control form-control-lg form-control-solid my-2'
                type='text'
              />
              <input
                value={dataId}
                onChange={(e) => setDataId(e.target.value)}
                placeholder='Data ID'
                className='form-control form-control-lg form-control-solid my-2'
                type='text'
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={updateMintPrice}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Update'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SetMintPriceForDataId
