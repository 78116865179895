import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

function ChangeGroupMaxSupply(props) {
  const [dataId, setDataId] = useState<string>('')
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const updateMaxSupply = async () => {
    setLoading(true)
    try {
        const updateMaxSupplyResult = await axios.post(`/api/mighty-samo/${dataId}/maxSupply`, { count })
        console.log('UPDATE MAX SUPPLY', dataId, updateMaxSupplyResult.data)
        toast.success('Admin successfully added!')
        setLoading(false)
    }
    catch(err) {
        console.log(err)
        setLoading(false)
        toast.error('Error updating group max supply.')
    }
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-6'>
                Change Group Max Supply
            </p>

            <div className='mb-5 w-75'>
              <input
                value={dataId}
                onChange={(e) => setDataId(e.target.value)}
                placeholder='Data ID'
                className='form-control form-control-lg form-control-solid my-3'
                type='text'
              />
              <input
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                placeholder='Count'
                className='form-control form-control-lg form-control-solid my-3'
                type='number'
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={updateMaxSupply}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Update'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangeGroupMaxSupply
